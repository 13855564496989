<template>
    <div style="display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;">
        <vue-html2pdf
            :show-layout="false"
            :filename="Pldo__title"
            :float-layout="true"
            :enable-download="false"
            :manual-pagination="true"
            :html-to-pdf-options="htmlToPdfOptions"
            :pdf-quality="2"
            :preview-modal="false"
            pdf-format="a4"
            pdf-content-width="1120"
            pdf-orientation="landscape"
            ref="html2Pdf"
            @beforeDownload="beforeDownload($event)"
        >
            <section slot="pdf-content">
                <v-app>
                    <div class="d-flex flex-column fill-height paper-a4-landscape">
                        <v-col class="pa-0 ma-0 mb-auto" style="max-height: 160px !important;">
                            <v-row no-gutters>
                                <v-col class="col-6 d-flex flex-column pt-3">
                                    <div>
                                        <span class="header-title">{{ Office__title  }}</span>
                                        <span class="header-subtitle font-weight-bold" v-if="Office__companyno != null">{{ '(' + Office__companyno + ')' }}</span>
                                    </div>
                                </v-col>
                                <v-col class="col-6 text-right pt-3"><span class="header-title">{{ $t('message.packingList').toUpperCase() }}</span></v-col>
                            </v-row>
                            <v-row no-gutters class="pt-3">
                                <v-col cols="5">
                                    <div class="d-flex flex-column">
                                        <span class="font-weight-bold">{{ $t('message.buyer') }}</span>
                                        <span>{{ Customer__title }}</span>
                                        <span>{{ Customer__address1 }}</span>
                                        <span>{{ Customer__address2 }}</span>
                                        <span>{{ Customer__address3 }}</span>
                                        <span>{{ Customer__city + ' ' + Customer__postcode}}</span>
                                        <span>{{ Customer__state }}</span>
                                        <span>{{ Customer__country }}</span>
                                        <span>{{ getTaxId() }}</span>
                                    </div>
                                </v-col>
                                <v-col class="col-3-5">
                                    <v-row no-gutters>
                                        <v-col cols="4" class="font-weight-bold">{{ $t('message.containerNo') }}</v-col>
                                        <v-col cols="8">
                                            <span v-for="(item,index) in Pldo.Containers">{{ item.Container.title + (Pldo.Containers.length - 1 > index ? ', ' : '') }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="4" class="font-weight-bold">{{ $t('message.grossWeight') }}</v-col>
                                        <v-col cols="8" v-if="Pldo__grossweight != ''">{{ Pldo__grossweight + (Pldo__grossweight_uofm != null ? " " + Pldo__grossweight_uofm : "") }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="4" class="font-weight-bold">{{ $t('message.netWeight') }}</v-col>
                                        <v-col cols="8" v-if="Pldo__netweight != ''">{{ Pldo__netweight + (Pldo__netweight_uofm != null ? " " + Pldo__netweight_uofm : "") }}</v-col>
                                    </v-row>
                                    <v-row no-gutters v-if="Contract__fsc != 0 && Contract__fsc != null">
                                        <v-col cols="4" class="font-weight-bold">{{ $t('message.certCode') }}</v-col>
                                        <v-col cols="8">
                                            {{ certification.cert_no }}
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters v-if="Contract__fsc != 0 && Contract__fsc != null">
                                        <v-col cols="4" class="font-weight-bold">{{ $t('message.expiryDate') }}</v-col>
                                        <v-col cols="8">
                                            {{ certification.cert_expiry_date }}
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="col-3-5">
                                    <v-row no-gutters>
                                        <v-col cols="4" class="font-weight-bold">{{ $t('message.date') }}</v-col>
                                        <v-col cols="8">{{ formatDate(Pldo__pldodate) }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="4" class="font-weight-bold">{{ $t('message.invoiceNumber') }}</v-col>
                                        <v-col cols="8">{{ Invoice__invno }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="4" class="font-weight-bold">{{ $t('message.contract') }}</v-col>
                                        <v-col cols="8">{{ Contract__title }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="4" class="font-weight-bold">{{ $t('message.vessel') }}</v-col>
                                        <v-col cols="8">{{ Contract__shipmentstatus_id == 6 ? Contract__shipmentstatus_text : null }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="4" class="font-weight-bold">{{ $t('message.etd') }}</v-col>
                                        <v-col cols="8">{{ formatDate(Shipment__etddate) }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="4" class="font-weight-bold">{{ $t('message.eta') }}</v-col>
                                        <v-col cols="8">{{ formatDate(Shipment__etadate) }}</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="px-0 pb-0 pt-3 ma-0 mb-auto">
                            <v-simple-table
                                    class="specification-table"
                                    dense
                            >
                                <template v-slot:default>
                                    <tr class="table-header">
                                        <td rowspan="2" class="px-0 font-weight-bold border-top-1 border-bottom-1 text-center width-25-pct">{{ $t('message.totalOfPackages') }}</td>
                                        <td class="px-0 font-weight-bold border-top-1">{{ $t('message.grade') }}</td>
                                        <td class="px-0 font-weight-bold border-top-1 text-center" v-if="fields['thickness']">{{ $t('message.thickness') }}</td>
                                        <td class="px-0 font-weight-bold border-top-1 text-center" v-if="fields['width']">{{ $t('message.width') }}</td>
                                        <td class="px-0 font-weight-bold border-top-1 text-center width-15-pct" v-if="fields['length']">{{ $t('message.length') }}</td>
                                        <td class="px-0 font-weight-bold border-top-1 text-center width-10-pct" v-if="grandTotalPieces > 0">{{ $t('message.pieces') }}</td>
                                        <td class="px-0 font-weight-bold border-top-1 text-center width-10-pct" v-if="fields['area'] && grandTotalArea > 0">{{ $t('message.area') }}</td>
                                        <td class="px-0 font-weight-bold border-top-1 text-center width-10-pct" v-if="fields['volume'] && grandTotalVolume > 0">{{ $t('message.volume') }}</td>
                                    </tr>
                                    <tr>
                                        <td class="px-0 font-weight-bold border-bottom-1"></td>
                                        <td class="px-0 font-weight-bold border-bottom-1 text-center" v-if="fields['thickness']"><span v-for="unit of units['thickness']">{{ unit.toLowerCase() }}</span></td>
                                        <td class="px-0 font-weight-bold border-bottom-1 text-center" v-if="fields['width']"><span v-for="unit of units['width']">{{ unit.toLowerCase() }}</span></td>
                                        <td class="px-0 font-weight-bold border-bottom-1 text-center" v-if="fields['length']">
                                            <span v-for="(unit, index) of units['length']">{{ unit.toLowerCase() + (index < (units['length'].length - 1) ? ', ' : '') }}</span>
                                        </td>
                                        <td class="px-0 font-weight-bold border-bottom-1 text-center" v-if="grandTotalPieces > 0">{{ $t('message.pcs') }}</td>
                                        <td class="px-0 font-weight-bold border-bottom-1 text-center" v-if="fields['area'] && grandTotalArea > 0"><span v-for="unit of units['area']">{{ unit.toLowerCase() }}</span></td>
                                        <td class="px-0 font-weight-bold border-bottom-1 text-center" v-if="fields['volume'] && grandTotalVolume > 0"><span v-for="unit of units['volume']">{{ unit.toLowerCase() }}</span></td>
                                    </tr>
                                    <template v-for="(item,index) in PackingListItems">
                                        <tr class="table-row-sub" v-if="index > 0 && PackingListItems[index - 1].Container.title != item.Container.title">
                                            <td class="text-center font-weight-bold">{{ totalBundles[PackingListItems[index - 1].Container.title] + ' ' + $t('message.packages') }}</td>
                                            <td class="text-center font-weight-bold"></td>
                                            <td class="text-center font-weight-bold" v-if="fields['thickness']"></td>
                                            <td class="text-center font-weight-bold" v-if="fields['width']"></td>
                                            <td class="text-center font-weight-bold" v-if="fields['length']">{{ $t('message.total') }}</td>
                                            <td class="text-center font-weight-bold" v-if="grandTotalPieces > 0">{{ totalPieces[PackingListItems[index - 1].Container.title] }}</td>
                                            <td class="text-center font-weight-bold" v-if="fields['area'] && grandTotalArea > 0">{{ formatThisNumber(totalArea[PackingListItems[index - 1].Container.title],'0,0.000') }}</td>
                                            <td class="text-center font-weight-bold" v-if="fields['volume'] && grandTotalVolume > 0">{{ formatThisNumber(totalVolume[PackingListItems[index - 1].Container.title],'0,0.0000') }}</td>
                                        </tr>
                                        <tr class="table-row-main" v-if="index == 0">
                                            <td colspan="7" v-if="useLcDescriptions"><span>{{ item.Container.title }}</span><span class="ml-8">{{ item.Salesdescription.lcdescription }}</span></td>
                                            <td colspan="7" v-else><span>{{ item.Container.title }}</span><span class="ml-8">{{ item.Salesdescription.title + getCertificationLabel(item.Salesdescription.title,item.Salesitem.certification_id) }}</span></td>
                                        </tr>
                                        <tr class="table-row-main" v-if="index > 0 && PackingListItems[index - 1].Container.title != item.Container.title && item.Salesdescription.title != PackingListItems[index - 1].Salesdescription.title">
                                            <td colspan="7" v-if="useLcDescriptions"><span>{{ item.Container.title }}</span><span class="ml-8">{{ item.Salesdescription.lcdescription }}</span></td>
                                            <td colspan="7" v-else><span>{{ item.Container.title }}</span><span class="ml-8">{{ item.Salesdescription.title + getCertificationLabel(item.Salesdescription.title,item.Salesitem.certification_id) }}</span></td>
                                        </tr>
                                        <tr class="table-row-main" v-if="index > 0 && PackingListItems[index - 1].Container.title == item.Container.title && item.Salesdescription.title != PackingListItems[index - 1].Salesdescription.title">
                                            <td colspan="7" v-if="useLcDescriptions"><span>{{ item.Container.title }}</span><span class="ml-8">{{ item.Salesdescription.lcdescription }}</span></td>
                                            <td colspan="7" v-else><span>{{ item.Container.title }}</span><span class="ml-8">{{ item.Salesdescription.title + getCertificationLabel(item.Salesdescription.title,item.Salesitem.certification_id) }}</span></td>
                                        </tr>
                                        <tr class="table-row-main" v-if="index > 0 && PackingListItems[index - 1].Container.title != item.Container.title && item.Salesdescription.title == PackingListItems[index - 1].Salesdescription.title">
                                            <td colspan="7" v-if="useLcDescriptions"><span>{{ item.Container.title }}</span><span class="ml-8">{{ item.Salesdescription.lcdescription }}</span></td>
                                            <td colspan="7" v-else><span>{{ item.Container.title }}</span><span class="ml-8">{{ item.Salesdescription.title + getCertificationLabel(item.Salesdescription.title,item.Salesitem.certification_id) }}</span></td>
                                        </tr>
                                        <tr class="table-row-sub">
                                            <td class="text-center">{{ item.PackingListItem.bundles }}</td>
                                            <td>{{ item.Grade.title }}</td>
                                            <td class="text-center" v-if="fields['thickness']">{{ item.PackingListItem.thickness }}</td>
                                            <td class="text-center" v-if="fields['width']">{{ item.PackingListItem.width }}</td>
                                            <td class="text-center" v-if="fields['length']">{{ item.PackingListItem.length }}</td>
                                            <td class="text-center" v-if="grandTotalPieces > 0">{{ item.PackingListItem.pieces }}</td>
                                            <td class="text-center" v-if="fields['area'] && grandTotalArea > 0">{{ formatThisNumber(item.PackingListItem.area,'0,0.000') }}</td>
                                            <td class="text-center" v-if="fields['volume'] && grandTotalVolume > 0">{{ formatThisNumber(item.PackingListItem.volume,'0,0.0000') }}</td>
                                        </tr>
                                        <tr class="table-row-sub" v-if="index == PackingListItems.length - 1">
                                            <td class="text-center font-weight-bold">{{ totalBundles[item.Container.title] + ' ' + $t('message.packages') }}</td>
                                            <td class="text-center font-weight-bold"></td>
                                            <td class="text-center font-weight-bold" v-if="fields['thickness']"></td>
                                            <td class="text-center font-weight-bold" v-if="fields['width']"></td>
                                            <td class="text-center font-weight-bold" v-if="fields['length']">{{ $t('message.total') }}</td>
                                            <td class="text-center font-weight-bold" v-if="grandTotalPieces > 0">{{ totalPieces[item.Container.title] }}</td>
                                            <td class="text-center font-weight-bold" v-if="fields['area'] && grandTotalArea > 0">{{ formatThisNumber(totalArea[item.Container.title],'0,0.000') }}</td>
                                            <td class="text-center font-weight-bold" v-if="fields['volume'] && grandTotalVolume > 0">{{ formatThisNumber(totalVolume[item.Container.title],'0,0.0000') }}</td>
                                        </tr>
                                    </template>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <div class="px-0 pb-0 pt-3 ma-0">
                            <v-simple-table class="specification-table" dense>
                                <template v-slot:default>
                                    <tr>
                                        <td class="px-0 font-weight-bold border-top-1 border-bottom-1 text-center width-25-pct">{{ grandTotalBundles + ' ' + $t('message.packages') }}</td>
                                        <td class="px-0 font-weight-bold border-top-1 border-bottom-1"></td>
                                        <td class="px-0 font-weight-bold border-top-1 border-bottom-1" v-if="fields['thickness']"></td>
                                        <td class="px-0 font-weight-bold border-top-1 border-bottom-1" v-if="fields['width']"></td>
                                        <td class="px-0 font-weight-bold border-top-1 border-bottom-1 text-center width-15-pct" v-if="fields['length']">{{ $t('message.total') }}</td>
                                        <td class="px-0 font-weight-bold border-top-1 border-bottom-1 text-center width-10-pct" v-if="grandTotalPieces > 0">{{ grandTotalPieces }}</td>
                                        <td class="px-0 font-weight-bold border-top-1 border-bottom-1 text-center width-10-pct" v-if="fields['area'] && grandTotalArea > 0">{{ formatThisNumber(grandTotalArea,'0,0.000') }}</td>
                                        <td class="px-0 font-weight-bold border-top-1 border-bottom-1 text-center width-10-pct" v-if="fields['volume'] && grandTotalVolume > 0">{{ formatThisNumber(grandTotalVolume,'0,0.0000') }}</td>
                                    </tr>
                                </template>
                            </v-simple-table>
                            <v-row no-gutters class="align-end">
                                <v-col cols="12" class="d-flex flex-column py-0">
                                    <template v-if="Customer__country_id == 3 && Contract__office_id == 3">
                                        <span class="stamp-area pt-2 pl-5">
                                            <img :src="'/static/img/appic/stamps/stampappltd.png'" height="80px"/>
                                        </span>
                                    </template>
                                    <template v-else-if="Customer__country_id == 3 && Contract__office_id == 1">
                                        <span class="stamp-area pt-2 pl-5">
                                            <img :src="'/static/img/appic/stamps/stampappsdnbhd.png'" height="80px"/>
                                        </span>
                                    </template>
                                    <template v-else>
                                        <div style="height: 80px"></div>
                                    </template>
                                    <span class="font-weight-bold">{{ $t('message.companyStamp') }}</span>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-app>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import {formatDate, log, numberFormat} from "../../../helpers/helpers";
    import {api} from "Api";
    import {
        // buyerCaptionTypes,
        // certificationsNew,
        // containerSizes,
        // currencies,
        // deliveryAddressTypes,
        // incoterms,
        // invoiceFooterNotes,
        offices,
        uofm
    } from "@/store/modules/appic/constants"
    import VueHtml2pdf from "vue-html2pdf";

    export default {
        name: "PrintPackingListBlob",
        props: ['packingListId'],
        components: {VueHtml2pdf},
        data() {
            return {
                certificationsNew: [],
                htmlToPdfOptions: {
                    image: {
                        type: 'jpeg',
                        quality: 0.70
                    },
                    html2canvas: {
                        scale: 1.5
                    },
                    jsPDF: {
                        orientation: 'landscape',
                        format: 'a4'
                    }
                },
                lcTerms: [],
                loaded: false,
                offices: offices,
                uofmQty: uofm.qty,
                uofmWeights: uofm.weights,
                useLcDescriptions: false,
                totalArea: [],
                totalBundles: [],
                totalPieces: [],
                totalVolume: [],
                Contract__fsc: 0,
                Contract__office_id: 1,
                Contract__paymentstatement_id: null,
                Contract__shipmentstatus_id: null,
                Contract__shipmentstatus_text: null,
                Contract__partial_no: null,
                Contract__revision_no: null,
                Contract__title: null,
                Contract__version: null,
                Customer__address1: null,
                Customer__address2: null,
                Customer__address3: null,
                Customer__city: null,
                Customer__country: null,
                Customer__country_id: 1,
                Customer__postcode: null,
                Customer__state: null,
                Customer__title: null,
                Customer__gstno: null,
                Customer__taxno_type: null,
                Invoice__invno: null,
                Office__companyno: null,
                Office__title: null,
                Office__stamp: null,
                PackingListItems: [],
                PackingListItems__itemsTotalArea: [],
                PackingListItems__itemsTotalBundles: 0,
                PackingListItems__itemsTotalQty: 0,
                PackingListItems__itemsTotalVolume: [],
                Pldo__id: null,
                Pldo__grossweight: null,
                Pldo__grossweight_uofm: 'KGS',
                Pldo__gwmeasurement_id: 53,
                Pldo__netweight: null,
                Pldo__netweight_uofm: 'KGS',
                Pldo__nwmeasurement_id: 53,
                Pldo__pldodate: null,
                Pldo__title: null,
                Pldo: {
                    Containers: []
                },
                Shipment__etadate: null,
                Shipment__etddate: null,
                fields: {
                    'area': null,
                    'length': null,
                    'thickness': null,
                    'volume': null,
                    'width': null,
                },
                units: {
                    'area': [],
                    'length': [],
                    'thickness': [],
                    'volume': [],
                    'width': [],
                },
                grandTotalArea: 0,
                grandTotalBundles: 0,
                grandTotalPieces: 0,
                grandTotalVolume: 0
            }
        },
        computed: {
            certification () {
                let certification = {
                    cert_no: '',
                    cert_expiry_date: ''
                }
                let obj = this.certificationsNew.find((certification) => certification.Certification.id == this.Contract__fsc)
                if(obj){
                    certification.cert_no = obj?.Certification?.cert_no
                    certification.cert_expiry_date = obj?.Certification?.cert_expiry_date
                }
                return certification
            },
            office (){
                let office = this.offices.find(o => o.Office.id == this.Contract__office_id)
                if(office == null){
                    office = this.offices.find(o => o.Office.id == 3)
                }
                return office
            }
        },
        methods: {
            async beforeDownload({ html2pdf, options, pdfContent }) {
                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    let reader = new FileReader()
                    let _this = this

                    //remove last page?
                    const totalPages = pdf.internal.getNumberOfPages()
                    if(totalPages > 1){
                        pdf.deletePage(totalPages)
                    }

                    reader.readAsDataURL(pdf.output('blob'));
                    reader.onloadend = function () {
                        let file = reader.result;
                        api
                            .post('/packinglists/pdf',{
                                pdf: {
                                    pldo_title: _this.Pldo__title,
                                    pldo_id: _this.Pldo__id,
                                    file: file
                                },
                            })
                            .then((response) => {
                                if(response.data.status == 'success') {
                                    _this.$emit('pdf-uploaded', response.data.url)
                                } else {
                                    _this.$emit('pdf-not-uploaded')
                                }
                            })
                            .catch(() => {
                                _this.$emit('pdf-not-uploaded')
                            })
                    }
                })
            },
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            getCertifications() {
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/certifications",{
                        })
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            getCertificationLabel(salesDescription, certificationId) {
                if(certificationId != null && certificationId != 0){
                    let certificationText = this.certificationsNew.find(c => c.Certification.id == certificationId)?.Certification?.title
                    let searchStrings =  ['FSC','PEFC','SVLK',certificationText];
                    let stringFound = false
                    searchStrings.forEach(string => {
                        if(salesDescription.indexOf(string) > 0) stringFound = true
                    })
                    if(stringFound){
                        return ''
                    } else {
                        return ', ' + certificationText
                    }
                } else {
                    return ''
                }
            },
            getLcBasedPaymentTerms() {
                return new Promise((resolve, reject) => {
                    log('get lc based payment terms')
                    api
                        .get("/print/paymentterms/lc/ids",{
                        })
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            getTaxId () {
                let taxId = ''
                if(this.Customer__gstno != null && this.Customer__taxno_type != null) taxId = this.Customer__taxno_type + ': ' + this.Customer__gstno
                if(this.Customer__gstno != null && this.Customer__taxno_type == null) taxId = this.Customer__gstno
                return taxId
            },
            getWeightUofm () {
                let grossWeightUofm = this.uofmWeights.find(u => u.Measurement.id == this.Pldo__gwmeasurement_id)
                if(grossWeightUofm){
                    this.Pldo__grossweight_uofm = grossWeightUofm.Measurement.title
                }
                let netWeightUofm = this.uofmWeights.find(u => u.Measurement.id == this.Pldo__nwmeasurement_id)
                if(netWeightUofm){
                    this.Pldo__netweight_uofm = netWeightUofm.Measurement.title
                }
            },
            loadPackingListById(packinglist_id) {
                let self = this
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/packinglists/" + packinglist_id,{
                        })
                        .then(response => {
                            for(let key in response.data.data[0]){
                                self[key] = response.data.data[0][key]
                            }
                            resolve(packinglist_id)
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(()=>{

                        })
                })
            },
            loadItemsByPackingList (packinglist_id) {
                let self = this
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/packinglists/" + packinglist_id + '/packinglistitems',{
                        })
                        .then(response => {
                            self.PackingListItems = response.data.data
                            self.fields = response.data.fields
                            self.units = response.data.units
                            resolve(packinglist_id)
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(()=>{
                            resolve(packinglist_id)
                        })
                })
            },
            async updateTotals () {
                let area = [], bundles = [], pieces = [], volume = []
                this.PackingListItems.forEach((item)=> {
                    let container = item.Container.title
                    if(item.PackingListItem?.area) {
                        if (container in area) {
                            area[container] += parseFloat(item.PackingListItem.area.toFixed(3))
                        } else {
                            area[container] = parseFloat(item.PackingListItem.area.toFixed(3))
                        }
                        this.grandTotalArea += parseFloat(item.PackingListItem.area.toFixed(3))
                    }
                    if(item.PackingListItem?.bundles) {
                        if (container in bundles) {
                            bundles[container] += item.PackingListItem.bundles
                        } else {
                            bundles[container] = item.PackingListItem.bundles
                        }
                        this.grandTotalBundles += item.PackingListItem.bundles
                    }
                    if(item.PackingListItem?.pieces) {
                        if (container in pieces) {
                            pieces[container] += item.PackingListItem.pieces
                        } else {
                            pieces[container] = item.PackingListItem.pieces
                        }
                        this.grandTotalPieces += item.PackingListItem.pieces
                    }
                    if(item.PackingListItem?.volume) {
                        if (container in volume) {
                            volume[container] += item.PackingListItem.volume
                        } else {
                            volume[container] = item.PackingListItem.volume
                        }
                        this.grandTotalVolume += item.PackingListItem.volume
                    }
                })
                this.totalArea = area
                this.totalBundles = bundles
                this.totalPieces = pieces
                this.totalVolume = volume
                return 'done'
            },
            uofmPrecision (uofm) {
                return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
            },
        },
        created() {
            this.$emit('pdf-uploading')
            this.getLcBasedPaymentTerms()
                .then((lcPaymentTerms) => {
                    this.lcTerms = lcPaymentTerms
                })
                .catch()
            this.getCertifications()
                .then((certifications) => {
                    this.certificationsNew = certifications
                })
            this.loadPackingListById(this.packingListId)
                .then(()=>{
                    if(this.lcTerms.includes(this.Contract__paymentstatement_id)){
                        this.useLcDescriptions = true
                    }
                    this.loadItemsByPackingList(this.Pldo__id)
                        .then((response)=>{
                            this.updateTotals().then(()=>{
                                this.loaded = true
                                this.$refs.html2Pdf.generatePdf()
                            })
                        })
                })
        }
    }
</script>

<style scoped>
@font-face {
    font-family: 'AppicArial';
    src: url('/static/fonts/Arial.woff2') format('woff2'),
    url('/static/fonts/Arial.woff2') format('woff'),
    url('/static/fonts/Arial.woff2') format('truetype');
}

.border-top-1 {
    border-top: 1px solid black !important;
}
.border-bottom-1 {
    border-bottom: 1px solid black !important;
}
.border-top-2 {
    border-top: 2px solid black;
}
.border-bottom-2 {
    border-bottom: 2px solid black !important;
}
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.col-4-5 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
}
.col-9-5 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
}

.document-caption {
    font-size: 16px !important;
    line-height: 18px;
}
.footer-note {
    font-size: 8pt !important;
}
.header-title {
    font-size: 20pt !important;
    line-height: 25px;
    font-weight: bold !important;
}
.header-subtitle {
    font-size: 10.5pt !important;
}
.paper-a4-landscape {
    height: 750px !important;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 10px;
    background-color: #FFFFFF !important;
    color: #000000 !important;
}
.specification-table table tr td {
    font-family: 'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:2px !important;
}
.specification-table table tr.table-header td {
    font-family: 'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:0px !important;
    height: 15px !important;
    vertical-align: top !important;
}
.specification-table table tr.table-row-main td {
    font-family: 'AppicArial' !important;
    line-height:18px !important;
    font-size:9pt !important;
    padding:0px !important;
    height: 19px !important;
}
.specification-table table tr.table-row-sub td {
    font-family: 'AppicArial' !important;
    line-height:13px !important;
    font-size:9pt !important;
    padding:0px !important;
    height: 15px !important;
}
.specification-table table tr td.table-total {
    height: 8px !important;
}
.stamp-area {
    height: 100px;
}
.v-application {
    font-family: 'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:2px !important;
    background-color: #FFFFFF !important;
}
.width-10-pct {
    width: 12% !important;
}
.width-12-pct {
    width: 12% !important;
}
.width-18-pct {
    width: 18% !important;
}
.width-25-pct {
    width: 25% !important;
}
body {
    /*width: 29cm !important;*/
    margin: 0 !important;
}
</style>